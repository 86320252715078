import {Fragment, memo, useCallback, useMemo} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'
import {useSelector} from 'react-redux'

import {
  CartItem,
  selectCartItemOptionTotalPrice,
  selectCartItemTotalPrice,
  selectHasRestrictedItems,
} from 'src/models/cart'
import Text from './Text'
import {RootState} from 'src/utilities/store'
import {ProductContract} from 'src/types/api'
import ProductCounter from 'src/components/ProductCounter'
import {mediaUrl} from 'src/utilities/functions'
import ExclamationMarkCircleIcon from 'src/assets/icons/exclamation-mark-circle.svg?react'
import {selectIsLoyaltyCardType} from 'src/models/catalog'
import {selectPriceWithCurrency} from 'src/models/profile'

interface CartProductProps {
  cartItem: CartItem
  editing?: boolean
  showError?: boolean
  hasShadow?: boolean
  restrictedErrorMsg?: string
  onAdd?: (id: string) => void
  onRemove?: (id: string) => void
}

interface CartProductOptionProps {
  cartItem: CartItem
  optionSetId: string
  option: ProductContract
  editing: boolean
  showError?: boolean
  parentOptionSetId?: string
}

interface MainContainerProps {
  showError?: boolean
  editing: boolean
  hasShadow?: boolean
  showRestrictedError?: boolean
}

interface ProductItemContainerProps {
  editing: boolean
}

interface TitleProps {
  showError?: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  padding: 0.75rem ${({hasShadow}) => (hasShadow ? 0.75 : 0)}rem 0.75rem ${({editing}) => (editing ? 0.75 : 0)}rem;
  display: grid;
  grid-template-columns: ${({editing}) => (editing ? 'auto 1fr' : '1fr')};
  column-gap: 1rem;

  ${({hasShadow, theme, showError, showRestrictedError}) =>
    hasShadow &&
    `
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
    border-radius: 0.563rem;
    border: 2px solid ${
      showRestrictedError ? theme.colors.error : showError ? theme.colors.item1 : theme.colors.alternativePrimaryText
    };
  `}
`

const ProductItemContainer = styled.div<ProductItemContainerProps>`
  display: grid;
  grid-template-columns: ${({editing}) => (editing ? '1fr' : '3.75rem 1fr')};
  align-items: center;
`

const ProductItemContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.25rem;
  align-items: center;
`

const ProductOptionsContainer = styled.div`
  padding-top: 1rem;
  display: grid;
  row-gap: 0.4rem;
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs<TitleProps>(({showError}) => ({
  color: showError ? 'error' : 'primaryText',
  style: {textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'},
}))<TitleProps>``

const ProductActionsContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  align-content: start;
`

const ProductImgContainer = styled.div`
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 0.563rem;
  overflow: hidden;
`

const ProductImg = styled.img.attrs({
  alt: 'product',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
`

const ContentContainer = styled.div`
  display: grid;
  align-content: space-between;
  row-gap: 1.5rem;
`

const LoyaltyEmailContainer = styled.div`
  padding-top: 1rem;
`

const CartProductOption = memo((props: CartProductOptionProps) => {
  const cartItemOptionTotalPrice = useSelector((state: RootState) =>
    selectCartItemOptionTotalPrice(state, {
      cartItem: props.cartItem,
      optionSetId: props.optionSetId,
      optionId: props.option.id!,
      parentOptionSetId: props?.parentOptionSetId,
    }),
  )
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, cartItemOptionTotalPrice))

  return (
    <ProductItemContainer editing={props.editing}>
      {!props.editing && <div />}
      <ProductItemContentContainer>
        <Text type="checkoutPageOptionDescription" color={props.showError ? 'error' : 'selectedOption'}>
          {props.option.title}
        </Text>
        {cartItemOptionTotalPrice > 0 && (
          <Text type="checkoutPageOptionDescription" color="secondaryText">
            {priceWithCurrency}
          </Text>
        )}
      </ProductItemContentContainer>
    </ProductItemContainer>
  )
})

const CartProduct = (props: CartProductProps) => {
  const cartItemTotalPrice = useSelector((state: RootState) =>
    selectCartItemTotalPrice(state, {cartItem: props.cartItem}),
  )
  const hasRestrictedItems = useSelector((state: RootState) => selectHasRestrictedItems(state, props.cartItem.id))
  const isLoyaltyCardType = useSelector((state: RootState) =>
    selectIsLoyaltyCardType(state, props.cartItem.product.id!),
  )
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, cartItemTotalPrice))

  const optionSets = useMemo(() => Object.values(props.cartItem?.optionSets ?? {}), [props.cartItem?.optionSets])

  const editing = useMemo(() => !!props.editing, [props.editing])

  const hasRestrictedError = !!props.restrictedErrorMsg && hasRestrictedItems

  const handleAdd = useCallback(() => {
    props.onAdd?.(props.cartItem.id)
  }, [props])

  const handleRemove = useCallback(() => {
    props.onRemove?.(props.cartItem.id)
  }, [props])

  return (
    <MainContainer
      showError={props.showError}
      showRestrictedError={hasRestrictedError}
      editing={editing}
      hasShadow={props.hasShadow}
    >
      {editing && (
        <ProductActionsContainer>
          <ProductImgContainer>
            <ProductImg src={mediaUrl(props.cartItem.product.images?.[0])} />
          </ProductImgContainer>
          <ProductCounter
            count={props.cartItem.count}
            addDisabled={isLoyaltyCardType}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        </ProductActionsContainer>
      )}
      <ContentContainer>
        <div>
          <ProductItemContainer editing={editing}>
            {!editing && (
              <Text type="productPageOptionName" color="selectedOption" align={props.hasShadow ? 'center' : 'left'}>
                {props.cartItem.count}x
              </Text>
            )}
            <ProductItemContentContainer>
              <Title
                type="checkoutPageOptionTitle"
                showError={!!props.restrictedErrorMsg && !!props.cartItem.product.isRestricted}
              >
                {props.cartItem.product.title}
              </Title>
              <Text type="checkoutPageOptionTitle">{priceWithCurrency}</Text>
            </ProductItemContentContainer>
          </ProductItemContainer>
          {!!optionSets.length && (
            <ProductOptionsContainer>
              {optionSets.map((parentOptionSet) => (
                <Fragment key={parentOptionSet?.optionSet?.id}>
                  {Object.values(parentOptionSet?.options ?? {}).map((option) => (
                    <CartProductOption
                      key={option?.option?.id}
                      cartItem={props?.cartItem}
                      optionSetId={parentOptionSet?.optionSet?.id!}
                      option={option?.option}
                      editing={editing}
                      showError={!!props?.restrictedErrorMsg && !!option?.option?.isRestricted}
                    />
                  ))}

                  {Object.values(props?.cartItem?.childOptionSets?.[parentOptionSet?.optionSet?.id!] ?? {}).map(
                    (childOptionSet) =>
                      Object.values(childOptionSet?.options ?? {}).map((option) => (
                        <CartProductOption
                          key={option?.option?.id}
                          cartItem={props?.cartItem}
                          optionSetId={childOptionSet?.optionSet?.id!}
                          option={option?.option}
                          editing={editing}
                          showError={!!props?.restrictedErrorMsg && !!option?.option?.isRestricted}
                          parentOptionSetId={parentOptionSet?.optionSet?.id}
                        />
                      )),
                  )}
                </Fragment>
              ))}
            </ProductOptionsContainer>
          )}

          {!!props?.cartItem?.loyaltyEmail && (
            <LoyaltyEmailContainer>
              <Text type="checkoutPageOptionDescription" color="secondaryText" align="right">
                {props.cartItem.loyaltyEmail}
              </Text>
            </LoyaltyEmailContainer>
          )}
        </div>
        {hasRestrictedError && (
          <ErrorContainer>
            <Text type="productBoxDescription" color="error">
              {props.restrictedErrorMsg}
            </Text>
            <ExclamationMarkCircleIcon />
          </ErrorContainer>
        )}
      </ContentContainer>
    </MainContainer>
  )
}

export default memo(CartProduct)
