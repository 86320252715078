import {createModel} from '@rematch/core'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import {
  CompanyInformationContract,
  CreateOrderContract,
  OptionSetContract,
  OrderContract,
  OrderType,
  ProductContract,
} from 'src/types/api'
import {RootState} from 'src/utilities/store'
import {selectSortedAndFilteredProducts} from './catalog'
import {TipsData} from 'src/components/TipsCard'
import {
  getCartTipsAmount,
  getCartTotalPrice,
  getCartTotalTakeAwayDiscountAmount,
  getRoundedNumber,
} from 'src/utilities/functions'

export interface CartItemOption {
  [optionId: string]: {
    option: ProductContract
    count: number
  }
}

export interface CartItemOptionSet {
  [optionSetId: string]: {
    optionSet: OptionSetContract
    options: CartItemOption
  }
}

export interface CartItemChildOptionSet {
  [parentOptionSetId: string]: CartItemOptionSet
}

export interface CartItem {
  id: string
  product: ProductContract
  count: number
  optionSets: CartItemOptionSet
  childOptionSets: CartItemChildOptionSet
  loyaltyEmail?: string
}

export interface Cart {
  orderType?: OrderType
  items: {[id: string]: CartItem}
  comment?: string
  promoCode?: string
  pagerNumber?: string
  invoiceEmail?: string
  guestIdentification?: string
  phoneNumber?: string
  isCompany?: boolean
  companyInfo?: Partial<CompanyInformationContract>
  tipsData?: TipsData
}

export interface ProductWithCartItem {
  product: ProductContract
  cartItem?: CartItem
}

export interface ProductsWithCartItemsAndCategories {
  [categoryId: string]: {
    product: ProductContract
    cartItem?: CartItem
  }[]
}

interface CartState {
  cart: Cart
}

const initialState: CartState = {
  cart: {
    items: {},
  },
}

export const cart = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addToCart(state, cartItem: CartItem) {
      state.cart.items[cartItem.id] = cartItem
    },
    removeFromCart(state, id: string) {
      if (!state.cart.items[id]) {
        return
      }
      delete state.cart.items[id]
    },
    increaseProductCount(state, id: string) {
      state.cart.items[id].count += 1
    },
    increaseProductCountBy(state, data: {id: string; count: number}) {
      state.cart.items[data.id].count += data.count
    },
    updateProductCount(state, data: {id: string; count: number}) {
      state.cart.items[data.id].count = data.count
    },
    decreaseProductCount(state, id: string) {
      state.cart.items[id].count -= 1
    },
    setOrderType(state, orderType: OrderType) {
      state.cart.orderType = orderType
    },
    updateCart(state, cart: Cart) {
      state.cart = cart
    },
    updateComment(state, comment: string) {
      state.cart.comment = comment
    },
    updatePagerNumber(state, pagerNumber: string) {
      state.cart.pagerNumber = pagerNumber
    },
    updateInvoiceEmail(state, invoiceEmail: string) {
      state.cart.invoiceEmail = invoiceEmail
    },
    updateGuestIdentification(state, guestIdentification: string) {
      state.cart.guestIdentification = guestIdentification
    },
    updatePromoCode(state, promoCode: string) {
      state.cart.promoCode = promoCode
    },
    updatePhoneNumber(state, phoneNumber: string) {
      state.cart.phoneNumber = phoneNumber
    },
    updateIsCompany(state, isCompany: boolean) {
      state.cart.isCompany = isCompany
    },
    updateCompanyInfo(state, companyInfo: Partial<CompanyInformationContract>) {
      state.cart.companyInfo = {...state.cart.companyInfo, ...companyInfo}
    },
    updateTipsData(state, tipsData: TipsData) {
      state.cart.tipsData = tipsData
    },
    clearPromoCode(state) {
      state.cart.promoCode = undefined
    },
    resetState(state) {
      state.cart = initialState.cart
    },
  },
  effects: (dispatch) => ({
    clearCart() {
      dispatch.cart.resetState()
      dispatch.orders.resetState()
      dispatch.timeSlots.resetState()
    },
  }),
})

export const selectCart = createSelector(
  (rootState: RootState) => rootState.cart,
  () => {},
  (state) => state.cart,
)

export const selectCartTakeAwayPrice = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  (_: RootState, cart?: Cart) => cart,
  (_, cartSelector, cart) =>
    Object.values(cart?.items ?? cartSelector.items).reduce<number>(
      (price, cartItem) => (price += cartItem.product.takeAwayPrice! * cartItem.count),
      0,
    ),
)

export const selectCartTakeAwayDiscountAmount = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  (_: RootState, cart?: Cart) => cart,
  (_, cartSelector, cart) => getCartTotalTakeAwayDiscountAmount(cart ?? cartSelector),
)

export const selectCartTotalPrice = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCartTakeAwayPrice,
  selectCartTakeAwayDiscountAmount,
  selectCart,
  (rootState: RootState, cart?: Cart) => ({cart, promoCodeDiscount: rootState.orders.orderPrice?.promoCodeDiscount}),
  (_, cartTakeAwayPrice, cartTakeAwayDiscountAmount, cartSelector, props) => {
    const cart = props.cart ?? cartSelector

    if (!Object.keys(cart.items).length) {
      return 0
    }

    const cartTotalPrice = getCartTotalPrice(cart)

    return getRoundedNumber(
      cartTotalPrice +
        (cart?.orderType === OrderType.TakeAway ? cartTakeAwayPrice : 0) -
        (cart?.orderType === OrderType.TakeAway ? cartTakeAwayDiscountAmount : 0) -
        (props.promoCodeDiscount ?? 0) +
        getCartTipsAmount(cart),
    )
  },
)

export const selectCartVisibleTotalPrice = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCartTotalPrice,
  (rootState: RootState) => rootState.orders.orderPrice?.availableLoyaltyAmount,
  (_, cartTotalPrice, availableLoyaltyAmount) => {
    const totalPrice = cartTotalPrice - (availableLoyaltyAmount ?? 0)
    if (totalPrice < 0) {
      return 0
    }

    return totalPrice
  },
)

export const selectCartItemTotalPrice = createSelector(
  (rootState: RootState) => rootState.cart,
  (_: RootState, data: {cartItem?: CartItem; product?: ProductContract}) => ({
    cartItem: data.cartItem,
    product: data.product,
  }),
  (_, {cartItem, product}) => {
    if (!cartItem) {
      return product?.price ?? 0
    }

    const productPrice = cartItem?.product?.price ?? 0
    const productCount = cartItem?.count ?? 0

    const optionsSetTotalSum = Object.values(cartItem?.optionSets ?? {}).reduce<number>((optionSetsSum, optionSet) => {
      const optionsTotalSum = Object.values(optionSet?.options ?? {}).reduce<number>((optionsSum, option) => {
        const optionPrice = option?.option?.price ?? 0
        const optionCount = option?.count ?? 0

        return (optionsSum += optionPrice * optionCount)
      }, 0)

      return (optionSetsSum += optionsTotalSum)
    }, 0)

    const childOptionsSetTotalSum = Object.values(cartItem?.childOptionSets ?? {}).reduce(
      (parentOptionSetsSum, parentOptionSet) => {
        const childOptionSetsTotalSum = Object.values(parentOptionSet ?? {}).reduce(
          (childOptionSetsSum, childOptionSet) => {
            const optionsTotalSum = Object.values(childOptionSet?.options ?? {}).reduce((optionsSum, option) => {
              const optionPrice = option?.option?.price ?? 0
              const optionCount = option?.count

              return (optionsSum += optionPrice * optionCount)
            }, 0)

            return (childOptionSetsSum += optionsTotalSum)
          },
          0,
        )

        return (parentOptionSetsSum += childOptionSetsTotalSum)
      },
      0,
    )

    return (productPrice + optionsSetTotalSum + childOptionsSetTotalSum) * productCount
  },
)

export const selectCartItemOptionTotalPrice = createSelector(
  (rootState: RootState) => rootState.cart,
  (_: RootState, props: {cartItem: CartItem; optionSetId: string; optionId: string; parentOptionSetId?: string}) =>
    props,
  (_, props) => {
    const cartItem = props.cartItem
    const option = props?.parentOptionSetId
      ? cartItem?.childOptionSets?.[props?.parentOptionSetId]?.[props?.optionSetId]?.options?.[props?.optionId]
      : cartItem?.optionSets?.[props?.optionSetId]?.options?.[props?.optionId]

    const productCount = cartItem?.count ?? 0
    const optionPrice = option?.option?.price ?? 0
    const optionCount = option?.count ?? 0

    return optionPrice * optionCount * productCount
  },
)

export const selectProductsWithCartItems = createSelector(
  (rootState: RootState) => rootState.cart,
  selectSortedAndFilteredProducts,
  selectCart,
  () => {},
  (_, sortedProducts, cart) =>
    sortedProducts
      .map((product) => {
        const item = {product}

        const items = Object.values(cart.items)
          .filter((cartItem) => cartItem?.product?.id === product?.id)
          .map((cartItem) => ({product, cartItem}))

        return product.optionSets?.length ? [item, ...items] : items.length ? items : item
      })
      .flat(),
)

export const selectProductsWithCartItemsAndCategories = createSelector(
  (rootState: RootState) => rootState.cart,
  selectProductsWithCartItems,
  () => {},
  (_, productsWithCartItems) =>
    productsWithCartItems.reduce<ProductsWithCartItemsAndCategories>((obj, productWithCartItem) => {
      const categoryId = productWithCartItem.product.categoryId!

      if (!obj[categoryId]) {
        obj[categoryId] = []
      }

      obj[categoryId].push(productWithCartItem)

      return obj
    }, {}),
)

export const selectCartProductsWithAgeToComply = createSelector(
  (rootState: RootState) => rootState.cart,
  () => {},
  (cart) =>
    Object.fromEntries(
      Object.entries(
        Object.values(cart.cart.items).reduce<{[productId: string]: number}>((obj, item) => {
          const ageToComply = item.product.ageToComply
          if (!ageToComply) {
            return obj
          }

          if (ageToComply > 0) {
            obj[item.product.id!] = ageToComply
          }

          return obj
        }, {}),
      ).sort(([_, aAge], [, bAge]) => bAge - aAge),
    ),
)

export const selectCartFromOrder = createSelector(
  (rootState: RootState) => rootState.cart,
  (_: RootState, order?: OrderContract) => order,
  (_, order): Cart => ({
    comment: order?.commentToKitchen ?? '',
    promoCode: order?.promoCode ?? '',
    orderType: order?.type,
    items:
      order?.products!.reduce<{[id: string]: CartItem}>((items, product) => {
        items[product.id!] = {
          id: product.id!,
          product,
          count: product.count!,
          optionSets: product.optionSets!.reduce<CartItemOptionSet>((optionSets, optionSet) => {
            optionSets[optionSet.id!] = {
              optionSet,
              options: optionSet.options!.reduce<CartItemOption>((options, option) => {
                options[option.id!] = {
                  option,
                  count: option.count!,
                }

                return options
              }, {}),
            }

            return optionSets
          }, {}),
          childOptionSets: {},
        }

        return items
      }, {}) ?? {},
    guestIdentification: order?.guestIdentification!,
  }),
)

export const selectCreateOrderData = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  selectCartTotalPrice,
  selectCartTakeAwayDiscountAmount,
  (rootState: RootState) => ({profile: rootState.profile}),
  (_, cart, cartTotalPrice, cartTakeAwayDiscountAmount, {profile}) => {
    const data: CreateOrderContract = {
      type: cart.orderType,
      commentToKitchen: cart.comment,
      promoCode: cart.promoCode,
      userVisiblePrice: cartTotalPrice,
      serviceFeeAmount: profile.user?.serviceFee,
      products: Object.values(cart.items).map((item) => ({
        id: item.product.id!,
        price: item.product.price,
        count: item.count,
        optionSets: Object.values(item?.optionSets ?? {}).map((parentOptionSet) => ({
          id: parentOptionSet.optionSet.id,
          order: parentOptionSet.optionSet.order,
          options: Object.values(parentOptionSet?.options ?? {}).map((parentOption) => ({
            id: parentOption.option.id!,
            price: parentOption.option.price,
            count: parentOption.count,
            loyaltyEmail: item?.loyaltyEmail,
            productType: parentOption?.option?.productType,
            optionSets: Object.values(item?.childOptionSets?.[parentOptionSet?.optionSet?.id!] ?? {}).map(
              (childOptionSet) => ({
                id: childOptionSet.optionSet.id,
                order: childOptionSet.optionSet.order,
                options: Object.values(childOptionSet?.options ?? {}).map((childOption) => ({
                  id: childOption.option.id!,
                  price: childOption.option.price,
                  count: childOption.count,
                  loyaltyEmail: item?.loyaltyEmail,
                  productType: childOption?.option?.productType,
                })),
              }),
            ),
          })),
        })),
        loyaltyEmail: item?.loyaltyEmail,
        productType: item?.product?.productType,
      })),
      pagerNumber: cart.pagerNumber,
      invoiceEmail: cart.invoiceEmail,
      guestIdentification: cart.guestIdentification,
      userPhoneNumber: cart.phoneNumber,
      companyInformation: cart.isCompany ? (cart.companyInfo as CompanyInformationContract) : undefined,
      tipAmount: getCartTipsAmount(cart),
      takeAwayDiscountAmount: cart.orderType === OrderType.TakeAway ? cartTakeAwayDiscountAmount : undefined,
    }

    return data
  },
)

export const selectHasRestrictedItemsInCart = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  () => {},
  (_, cart) => {
    return Object.values<CartItem>(cart?.items ?? {}).some((cartItem) => {
      if (cartItem?.product?.isRestricted) {
        return true
      }

      const optionSets = Object.values<{optionSet: OptionSetContract; options: CartItemOption}>(
        cartItem?.optionSets ?? {},
      )

      const options = optionSets
        .map((optionSet) => Object.values<{option: ProductContract; count: number}>(optionSet?.options ?? {}))
        .flat()

      return options.some(({option}) => !!option?.isRestricted)
    })
  },
)

export const selectHasRestrictedItems = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  (_: RootState, cartItemId: string) => cartItemId,
  (_, cart, cartItemId) => {
    const cartItem = cart?.items?.[cartItemId]
    if (!cartItem) {
      return false
    }

    if (cartItem?.product?.isRestricted) {
      return true
    }

    const optionSets = Object.values<{optionSet: OptionSetContract; options: CartItemOption}>(
      cartItem?.optionSets ?? {},
    )

    const options = optionSets
      .map((optionSet) => Object.values<{option: ProductContract; count: number}>(optionSet?.options ?? {}))
      .flat()

    return options.some(({option}) => !!option?.isRestricted)
  },
)

export const selectCartTipsAmount = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  (_, cart) => getCartTipsAmount(cart),
)

export const selectIsCartEmpty = createSelector(
  (rootState: RootState) => rootState.cart,
  selectCart,
  (_, cart) => !Object.keys(cart.items).length,
)
