import {PropsWithChildren, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {Dispatch, RootState} from 'src/utilities/store'

interface TestGroupHostContainerProps extends PropsWithChildren {}

const Container = styled.div`
  display: grid;
`

const TestGroupHostContainer = ({children}: TestGroupHostContainerProps) => {
  const [clickCount, setClickCount] = useState<number>(0)

  const testGroupHost = useSelector((state: RootState) => state.app.testGroupHost)

  const dispatch = useDispatch<Dispatch>()

  const handleClick = () => {
    if (clickCount === 4) {
      const value = prompt('Enter GroupHost value:', testGroupHost)
      if (value !== null) {
        dispatch.app.setTestGroupHost(value)
      }

      setClickCount(0)

      return
    }

    setClickCount((prevClickCount) => prevClickCount + 1)
  }

  return <Container onClick={handleClick}>{children}</Container>
}

export default TestGroupHostContainer
