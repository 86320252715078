import {useEffect} from 'react'
import * as Sentry from '@sentry/react'
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom'

if (process.env.VITE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VITE_APP_SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [window.location.origin, process.env.VITE_APP_API_URL],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', process.env.VITE_APP_API_URL],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    environment: process.env.VITE_APP_ENV,
  })
}
