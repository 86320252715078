import {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import IntercomButton from 'src/components/IntercomButton'
import PrimaryButton from 'src/components/PrimaryButton'
import IconButton from 'src/components/IconButton'
import Rating from 'src/components/Rating'
import TipsAmount, {Type} from 'src/components/Tips'
import {Dispatch, RootState} from 'src/utilities/store'
import {OrderContract, PaymentStatus} from 'src/types/api'
import PageLoader from 'src/components/PageLoader'
import ContentContainer from 'src/components/ContentContainer'

const FOOTER_HEIGHT = '4.75rem'
const BACK_BUTTON_CONTAINER_HEIGHT = '3.625rem'

interface LocationState {
  rating?: number
  order?: OrderContract
}

const MainContainer = styled(ContentContainer)`
  padding: calc(1rem + ${BACK_BUTTON_CONTAINER_HEIGHT}) 0.75rem calc(2rem + ${FOOTER_HEIGHT}) 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
  background-color: ${({theme}) => theme.colors.background};
  z-index: 2;
`

const FooterContentContainer = styled(ContentContainer)`
  display: grid;
  grid-template-columns: ${({theme}) => theme.sizes.iconButtonSize}px 1fr ${({theme}) => theme.sizes.iconButtonSize}px;
  column-gap: 1.25rem;
  align-items: center;
  justify-items: center;
  height: ${FOOTER_HEIGHT};
`

const BackButtonContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  z-index: 1;
`

const BackButtonContentContainer = styled(ContentContainer)`
  height: ${BACK_BUTTON_CONTAINER_HEIGHT};
  display: flex;
  align-items: flex-end;
`

const RatingContainer = styled.div`
  padding-bottom: 4.375rem;
`

const Appraisals = () => {
  const params = useParams<{tabletId: string; appraisalId: string}>()
  const location = useLocation()
  const locationState = location.state as LocationState
  const navigate = useNavigate()

  const {t} = useTranslation()

  const [tipsAmount, setTipsAmount] = useState<number>(0)
  const [tipped, setTipped] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(!!params.appraisalId)

  const dispatch = useDispatch<Dispatch>()

  const createAppraisalLoading = useSelector((state: RootState) => state.loading.effects.appraisals.createAppraisal)
  const addTipToAppraisalLoading = useSelector((state: RootState) => state.loading.effects.appraisals.addTipToAppraisal)
  const user = useSelector((state: RootState) => state.profile.user)

  const loading = useMemo(
    () => createAppraisalLoading || addTipToAppraisalLoading,
    [addTipToAppraisalLoading, createAppraisalLoading],
  )

  const isOrderAppraisal = useMemo(() => !!locationState?.order, [locationState?.order])

  const checkAppraisal = async () => {
    try {
      const appraisal = await dispatch.appraisals.getAppraisal(params.appraisalId!)

      if (appraisal.paymentStatus === PaymentStatus.Pending) {
        setTimeout(checkAppraisal, 5000)
        return
      }

      if (appraisal.orderId && appraisal.paymentStatus === PaymentStatus.Paid) {
        navigate(`/${appraisal.tabletId}/${appraisal.orderId}/status`, {
          state: {rating: appraisal.rating, tips: appraisal.tipAmount},
          replace: true,
        })
        return
      }

      if (appraisal.tipAmount) {
        setTipsAmount(appraisal.tipAmount)
      }

      if (appraisal.paymentStatus === PaymentStatus.Paid) {
        setTipped(true)
      }

      if (appraisal.orderId) {
        const order = await dispatch.orders.fetchOrder({id: appraisal.orderId})

        navigate(location.pathname, {state: {rating: appraisal.rating, order}, replace: true})
        return
      }

      navigate(location.pathname, {state: {rating: appraisal.rating}, replace: true})
    } catch (error) {
      console.error(error)
    } finally {
      setShowLoader(false)
    }
  }

  const createAppraisal = async () => {
    try {
      const appraisal = await dispatch.appraisals.createAppraisal({
        tabletId: params.tabletId,
        orderId: locationState?.order?.id,
        rating: locationState?.rating,
        tipAmount: tipsAmount,
      })

      window.open(appraisal.externalPaymentLink!, '_self')
    } catch (error) {
      console.error(error)
    }
  }

  const addTipToAppraisal = async () => {
    try {
      const appraisal = await dispatch.appraisals.addTipToAppraisal({
        id: params.appraisalId!,
        tipAmount: tipsAmount,
      })

      window.open(appraisal.externalPaymentLink!, '_self')
    } catch (error) {
      console.error(error)
    }
  }

  const handleBack = () => {
    navigate(`/${params.tabletId}/${locationState?.order?.id}/status`, {
      state: {rating: locationState?.rating},
      replace: true,
    })
  }

  const handleRate = async (rating: number) => {
    try {
      if (params.appraisalId) {
        const appraisal = await dispatch.appraisals.addRatingToAppraisal({
          id: params.appraisalId,
          rating,
        })

        navigate(location.pathname, {state: {rating: appraisal.rating}, replace: true})
        return
      }

      const appraisal = await dispatch.appraisals.createAppraisal({
        rating,
        tabletId: params.tabletId,
      })

      navigate(`${location.pathname}/${appraisal.id}`, {state: {rating: appraisal.rating}, replace: true})
    } catch (error) {
      console.error(error)
    }
  }

  const handlePay = () => {
    if (params.appraisalId) {
      addTipToAppraisal()
      return
    }

    createAppraisal()
  }

  const handleStart = () => {
    navigate(`/${params.tabletId}`)
  }

  useEffect(() => {
    if (!params.appraisalId) {
      return
    }

    checkAppraisal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (showLoader) {
    return <PageLoader />
  }

  return (
    <MainContainer>
      {isOrderAppraisal && (
        <BackButtonContainer>
          <BackButtonContentContainer>
            <IconButton name="arrow-back" variant="yellow" disabled={loading} onClick={handleBack} />
          </BackButtonContentContainer>
        </BackButtonContainer>
      )}

      <RatingContainer>
        <Rating ratedRating={locationState?.rating} disabled={!!locationState?.rating || loading} onRate={handleRate} />
      </RatingContainer>

      <TipsAmount
        type={isOrderAppraisal ? Type.PERCENTAGES : Type.FIXED_AMOUNT}
        values={isOrderAppraisal ? [5, 10, 15] : [0.5, 1, 1.5]}
        orderPrice={locationState?.order?.totalPrice}
        amount={tipsAmount}
        disabled={loading || tipped}
        tipped={tipped}
        onAmountChange={setTipsAmount}
      />

      <FooterContainer>
        <FooterContentContainer>
          {user?.enableSupportChat ? <IntercomButton /> : <div />}

          {!!tipsAmount && !tipped && (
            <PrimaryButton maxWidth="13.5rem" disabled={loading} onClick={handlePay}>
              {t('pages.appraisals.payButtonText')}
            </PrimaryButton>
          )}

          {tipped && (
            <PrimaryButton maxWidth="13.5rem" onClick={handleStart}>
              {t('pages.appraisals.startButtonText')}
            </PrimaryButton>
          )}
        </FooterContentContainer>
      </FooterContainer>
    </MainContainer>
  )
}

export default Appraisals
