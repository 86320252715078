import {memo, useCallback} from 'react'
import styled from 'styled-components'

import {CartItem} from 'src/models/cart'
import CartProduct from './CartProduct'

interface CartProductsProps {
  cartItems: CartItem[]
  editing?: boolean
  cartProductsWithAgeToComply?: {[productId: string]: number}
  showErrors?: boolean
  hasShadow?: boolean
  restrictedErrorMsg?: string
  onAdd?: (id: string) => void
  onRemove?: (id: string) => void
}

const Container = styled.div`
  display: grid;
  row-gap: 0.75rem;
`

const CartProducts = (props: CartProductsProps) => {
  const showError = useCallback(
    (productId: string) => {
      if (!props.showErrors || !props.cartProductsWithAgeToComply) {
        return false
      }

      const ages = Object.values(props.cartProductsWithAgeToComply)

      return props.cartProductsWithAgeToComply[productId] === ages[0]
    },
    [props.cartProductsWithAgeToComply, props.showErrors],
  )

  return (
    <Container>
      {props.cartItems.map((cartItem) => (
        <CartProduct
          key={cartItem.id}
          cartItem={cartItem}
          editing={props.editing}
          showError={showError(cartItem.product.id!)}
          hasShadow={props.hasShadow}
          restrictedErrorMsg={props.restrictedErrorMsg}
          onAdd={props.onAdd}
          onRemove={props.onRemove}
        />
      ))}
    </Container>
  )
}

export default memo(CartProducts)
