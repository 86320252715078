import {createModel} from '@rematch/core'
import {Location} from 'react-router-dom'

import {RootModel} from '.'

interface AppState {
  sessionId: string
  languageCode: string
  backgroundLocation: Location | null
  lastTabletId: string
  testGroupHost: string
}

const initialState: AppState = {
  sessionId: '',
  languageCode: '',
  backgroundLocation: null,
  lastTabletId: '',
  testGroupHost: '',
}

export const app = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSessionId(state, sessionId: string) {
      state.sessionId = sessionId
    },
    setLanguageCode(state, languageCode: string) {
      state.languageCode = languageCode
    },
    setBackgroundLocation(state, backgroundLocation: Location | null) {
      state.backgroundLocation = backgroundLocation
    },
    setLastTabletId(state, lastTabletId: string) {
      state.lastTabletId = lastTabletId
    },
    setTestGroupHost(state, testGroupHost: string) {
      state.testGroupHost = testGroupHost
    },
  },
})
