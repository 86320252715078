import styled, {AnyStyledComponent} from 'styled-components'
import {HTMLInputTypeAttribute, InputHTMLAttributes, useState} from 'react'
import {useSelector} from 'react-redux'
import {MdVisibility, MdVisibilityOff} from 'react-icons/md'

import Text from 'src/components/Text'
import ArrowDownIcon from 'src/assets/icons/arrow-down.svg'
import {CallingCodeContract} from 'src/types/api'
import {selectCallingCode} from 'src/models/profile'
import {RootState} from 'src/utilities/store'
import {allowOnlyIntegers} from 'src/utilities/functions'

interface PhoneNumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  info?: string
  callingCodes?: CallingCodeContract[]
  selectedCallingCodeId?: string
  onCallingCodeSelect?: (callingCodeId: string) => void
}

interface DropdownArrowProps {
  open?: boolean
}

const MainContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const InputContainer = styled.div`
  height: 3rem;
  border: 1px solid rgba(60, 60, 67, 0.29);
  border-radius: 0.625rem;
  display: grid;
  grid-template-columns: 4.5rem 1fr;
`

const PrefixContainer = styled.div`
  border-right: 1px solid rgba(60, 60, 67, 0.29);
  position: relative;
`

const PrefixButton = styled.button.attrs({
  type: 'button',
})`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

const InputContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

const Input = styled.input`
  padding: 0 0.5rem;
  outline: none;
  border: none;
  color: ${({theme}) => theme.colors.primaryText};
  ${({theme}) => ({...theme.typography.menuItems})};
  background-color: transparent;

  ::placeholder {
    color: ${({theme}) => theme.colors.secondaryText};
  }
`

const Info = styled(Text as unknown as AnyStyledComponent).attrs({
  style: {fontSize: '0.625rem', lineHeight: '140%'},
})``

const DropdownArrow = styled.img.attrs<DropdownArrowProps>({
  alt: 'dropdown-arrow',
  src: ArrowDownIcon,
})<DropdownArrowProps>`
  width: 1rem;
  height: 1rem;
  object-fit: contain;

  ${({open}) => !!open && `transform: rotate(180deg);`}
`

const DropdownContainer = styled.div`
  max-height: 3.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  overflow-y: auto;
  display: grid;
  row-gap: 0.25rem;
  background-color: ${({theme}) => theme.colors.whiteItem};
`

const DropdownItemContainer = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background-color: transparent;
  padding: 0.25rem 1rem 0.25rem 0;
  cursor: pointer;
`

const IconContainer = styled.button`
  align-self: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  border: none;
  background-color: transparent;
  color: ${({theme}) => theme.colors.secondaryText};
`

const PhoneNumberInput = ({
  info,
  callingCodes,
  selectedCallingCodeId,
  onCallingCodeSelect,
  ...props
}: PhoneNumberInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [inputType, setInputType] = useState<HTMLInputTypeAttribute>('password')

  const callingCode = useSelector((state: RootState) => selectCallingCode(state, selectedCallingCodeId))

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const toggleInputType = () => {
    setInputType((prevInputType) => (prevInputType === 'password' ? 'text' : 'password'))
  }

  const handleCallingCodeSelect = (callingCodeId: string) => {
    onCallingCodeSelect?.(callingCodeId)

    toggleOpen()
  }

  return (
    <MainContainer>
      <InputContainer>
        <PrefixContainer>
          <PrefixButton onClick={toggleOpen}>
            <Text type="menuItems">{callingCode?.code}</Text>

            <DropdownArrow open={open} />
          </PrefixButton>

          {open && (
            <DropdownContainer>
              {callingCodes?.map((code) => (
                <DropdownItemContainer key={code.id} onClick={() => handleCallingCodeSelect(code.id!)}>
                  <Text type="menuItems">{code.code}</Text>
                </DropdownItemContainer>
              ))}
            </DropdownContainer>
          )}
        </PrefixContainer>

        <InputContentContainer>
          <Input type={inputType} onKeyDown={allowOnlyIntegers} {...props} />

          <IconContainer type="button" onClick={toggleInputType}>
            {inputType === 'password' ? <MdVisibility /> : <MdVisibilityOff />}
          </IconContainer>
        </InputContentContainer>
      </InputContainer>

      {!!info && (
        <Info type="menuItems" color="secondaryText">
          {info}
        </Info>
      )}
    </MainContainer>
  )
}

export default PhoneNumberInput
