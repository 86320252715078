import {ComponentPropsWithRef, forwardRef, useEffect} from 'react'
import styled from 'styled-components'
import DatePicker, {ReactDatePickerProps, registerLocale} from 'react-datepicker'
import {useTranslation} from 'react-i18next'
import {Locale} from 'date-fns/locale'
import {lt} from 'date-fns/locale/lt'
import {ru} from 'date-fns/locale/ru'
import {fr} from 'date-fns/locale/fr'

import {LanguageCode} from 'src/types/api'

const LOCALE: {[key in LanguageCode]?: Locale} = {
  lt,
  ru,
  fr,
}

interface DateInputProps extends ReactDatePickerProps {
  error?: boolean
  icon?: string
}

interface CustomInputProps extends ComponentPropsWithRef<'button'> {
  error?: boolean
  icon?: string
}

interface ButtonProps {
  error?: boolean
  hasValue?: boolean
  hasIcon?: boolean
}

const Container = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: ${({theme}) => theme.colors.primaryItem};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({theme}) => theme.colors.primaryItem};
    border-radius: 0.3rem;
  }

  .react-datepicker__day--selected {
    background-color: ${({theme}) => theme.colors.primaryItem};
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({theme}) => theme.colors.black};
  }
`

const Button = styled.button.attrs<ButtonProps>({
  type: 'button',
})<ButtonProps>`
  ${({theme}) => ({...theme.typography.buttonText})}
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: ${({hasIcon}) => `0 ${hasIcon ? 3.125 : 0.75}rem 0 1.25rem`};
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.whiteItem};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({theme, error}) => (error ? theme.colors.error : theme.colors.whiteItem)};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  color: ${({theme, hasValue}) => `${theme.colors.primaryText}${hasValue ? '' : 'B3'}`};
  text-align: start;
  position: relative;

  :enabled {
    cursor: pointer;
  }

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  display: grid;
  place-content: center;
`

const Icon = styled.img`
  width: 1.625rem;
  height: 1.625rem;
  object-fit: contain;
`

const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({value, placeholder, error, icon, ...props}, ref) => {
    return (
      <Button ref={ref} error={error} hasValue={!!value} hasIcon={!!icon} {...props}>
        {value || placeholder}
        {!!icon && (
          <IconContainer>
            <Icon src={icon} alt={icon} />
          </IconContainer>
        )}
      </Button>
    )
  },
)

const DateInput = forwardRef<DatePicker, DateInputProps>(({error, icon, ...props}, ref) => {
  const {t, i18n} = useTranslation()

  useEffect(() => {
    const locale = LOCALE[i18n.language as LanguageCode]
    if (!locale) {
      return
    }

    registerLocale(i18n.language, locale)
  }, [i18n.language])

  return (
    <Container>
      {/* @ts-expect-error */}
      <DatePicker
        ref={ref}
        locale={i18n.language}
        showTimeSelect
        timeCaption={t('pages.dynamicPage.components.dateInput.timeLabel')}
        dateFormat="Pp"
        customInput={<CustomInput error={error} icon={icon} />}
        popperPlacement="bottom-start"
        timeIntervals={15}
        {...props}
      />
    </Container>
  )
})

export default DateInput
