import {createModel} from '@rematch/core'
import {AxiosResponse} from 'axios'
import {createSelector} from 'reselect'

import {RootModel} from '.'
import {CustomPageAccessType, CustomPageType, CustomWebsiteContract, CustomWebsitePageContract} from 'src/types/api'
import api from 'src/utilities/api'
import {RootState} from 'src/utilities/store'

interface WebsiteState {
  websiteData: CustomWebsiteContract | null
}

const initialState: WebsiteState = {
  websiteData: null,
}

export const website = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setWebsiteData(state, websiteData: CustomWebsiteContract) {
      state.websiteData = websiteData
    },
  },
  effects: (dispatch) => ({
    async fetchWebsiteData(groupId: string) {
      const {data}: AxiosResponse<CustomWebsiteContract> = await api.get(`/qr/website/${groupId}`)

      dispatch.website.setWebsiteData(data)
    },
    async sendReview({groupId, data}: {groupId: string; data: FormData}) {
      await api.post(`/qr/website/${groupId}/review`, data)
    },
    async sendApplication({groupId, data}: {groupId: string; data: FormData}) {
      await api.post(`/qr/website/${groupId}/application`, data)
    },
  }),
})

export const selectPagesObj = createSelector(
  (rootState: RootState) => rootState.website,
  () => {},
  (state) =>
    (state.websiteData?.pages ?? []).reduce<{[path: string]: CustomWebsitePageContract}>((obj, page) => {
      const path = page.path
      if (path) {
        obj[path] = page
      }

      return obj
    }, {}),
)

export const selectPage = createSelector(
  (rootState: RootState) => rootState.website,
  selectPagesObj,
  (_: RootState, path?: string) => path,
  (_, pagesObj, path) => {
    if (!path) {
      return
    }

    return pagesObj[path]
  },
)

export const selectMenuFooterPages = createSelector(
  (rootState: RootState) => rootState.website,
  (state) => {
    return (state.websiteData?.pages ?? []).filter(
      (page) => page.type === CustomPageType.MenuFooter && page.accessType === CustomPageAccessType.UrlAndSidebar,
    )
  },
)

export const selectCustomPages = createSelector(
  (rootState: RootState) => rootState.website,
  (state) => {
    return (state.websiteData?.pages ?? []).filter(
      (page) => page.type !== CustomPageType.MenuFooter && page.accessType === CustomPageAccessType.UrlAndSidebar,
    )
  },
)

export const selectHasCustomPages = createSelector(
  (rootState: RootState) => rootState.website,
  selectMenuFooterPages,
  selectCustomPages,
  (_, menuFooterPages, customPages) => {
    return !!menuFooterPages.length || !!customPages.length
  },
)
